@import "~styles/zeplin-colors";

.wrap {
  display: flex;
  justify-content: flex-start;
}

.active-programs-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-gap: 1rem;
  justify-content: flex-start;
  align-content: flex-start;
  margin: 2vh auto;
}

.non-active-programs-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-gap: 1rem;
  justify-content: flex-start;
  align-content: flex-start;
  margin: 2vh auto;
}

.non-active-programs-title {
  margin-top: 32px;
}

.subtitle {
  display: block;
  color: $gray-7;
  margin-bottom: 0 !important;
}

.subtitle-text {
  display: block;
  color: $gray-9;
  margin-bottom: 0 !important;
}

.successful-program {
  color: $green-1;
}

.emptyWrap {
  display: block;
  width: 100%;
}

.emptyOuterWrap {
  display: flex;
  justify-content: center;
}

.emptyInnerWrap {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
}

.emptyAddButton {
  margin-top: 35px;
}

.header-wrap {
  display: flex;
  justify-content: space-between;
}

.patient-program-info-wrap {
  width: 100%;
}

.medicine-span {
  display: block;
}

.link {
  color: $blue-2;
  cursor: pointer;
}
