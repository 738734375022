@import "~styles/zeplin-colors";

.qa-wrap {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}

.question-wrap {
  width: 100%;
}

.question {
  font-weight: 500;
}

.answer:first-child {
  margin-top: 0 !important;
}

.answer {
  display: block !important;
  margin-top: 10px !important;

  :global(.ant-radio) {
    vertical-align: top;
    margin-top: 3px;
  }
}

.answer-text {
  margin-bottom: 0;
  display: inline-block;
  width: 420px;
  white-space: normal;
}

.question-answer-wrap {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.question-answer-left {
  padding-right: 10px;
  width: 50%;
}

.question-answer-right {
  padding-left: 10px;
  width: 50%;
}

.tip-wrap {
  box-shadow: 0 0 5px $gray-5;
}

.tip-title {
  display: block;
  padding: 5px 16px;
  font-weight: 500;
  border-bottom: 1px solid $gray-5;
}

.tip-description {
  display: block;
  padding: 12px 16px;
}

.submit-wrap {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
}

.submit {
  color: white;
}
