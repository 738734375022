$pliva-blue-gray: #bcc3da;
$blue-2: #65a3ff;
$blue-0: #1267e5;
$white: #fff;
$pliva-blue: #14214e;
$blue-1: #3485ff;
$blue-3: #e4efff;
$gray-8: #595959;
$gray-4: #e8e8e8;
$gray-9: #262626;
$gray-5: #d9d9d9;
$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-1: #fafafa;
$red-1: #ed2733;
$green-1: #1ac120;
$pale-grey: #f1f2f5;
