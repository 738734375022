@import "~styles/zeplin-colors";

.wrap {
  min-height: calc(100vh - 88px);
}

.doubleHeaderWrap {
  min-height: calc(100vh - 176px);
}

.full {
  min-height: 100vh;
}
