@import "~styles/zeplin-colors";

.wrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $pale-grey;
}

.content-wrap {
  width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo-wrap {
  display: flex;
  justify-content: center;
}

.center-text {
  text-align: center;
}

.title {
  letter-spacing: 2.4px;
}

.form-wrap {
  margin-top: 50px;
}
