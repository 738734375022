.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 24px;
  max-height: 64px;
  min-height: 64px;
  background: white;

  :global(.ant-typography) {
    margin: 0;
  }
}

.title-wrap {
  display: flex;

  div {
    display: inline-flex;
    margin-right: 24px;
  }
}
