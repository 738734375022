@import "~styles/zeplin-colors";

.value {
  line-height: 1.27;
  letter-spacing: normal;
  color: $gray-9;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

.subtitle {
  color: $gray-7;
  line-height: 22px;
}

.card {
  display: inline-flex;
  flex-direction: column;
  width: 280px;
  border-color: $gray-5 !important;

  // overrides the title in the ant card
  :global(.ant-card-head-title) {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $gray-9;
  }

  // overrides the link in the ant card
  :global(.ant-card-extra a) {
    color: $blue-1;
  }

  // overrides the padding in the ant card body
  :global(.ant-card-body) {
    padding: 25px;
  }

  // overrides the margin on ant's p tag
  :global(.ant-typography p) {
    margin-bottom: 4px;
  }

  // overrides the border color for the separator
  :global(.ant-card-head) {
    border-color: $gray-5 !important;
  }
}
