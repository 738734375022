@import "~styles/zeplin-colors";

.wrapper {
  display: inline-flex;
  grid-column-start: 1;
  grid-column-end: 4;
  height: 280px;
  background-color: $white;
  border-radius: 2px;
  border: solid 1px $gray-5;
  min-width: 880px;
}

img {
  height: 100%;
  align-self: center;
}

.textWrapper {
  padding: 32px 24px 20px 48px;
}

.title {
  line-height: 38px;
  margin-bottom: 20px;
}

.subtitle {
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 12px;
}

.text {
  line-height: 22px;
}

@media only screen and (max-width: 1400px) {
  .wrapper {
    grid-column-start: 1;
    grid-column-end: 5;
  }
}
