@import "~styles/zeplin-colors";

.text {
  color: $blue-1;
  line-height: 22px;
}

.card {
  display: inline-flex;
  justify-content: center;
  width: 280px;
  height: 280px;
  border-color: $gray-5 !important;

  :global(.ant-card-body) {
    height: 100%;
    width: 100%;
  }
}

.cardContent {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconWrap {
  margin-bottom: 10px;
}
