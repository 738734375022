@import "~styles/zeplin-colors";

.header {
  padding: 16px;
}

.program-info {
  padding: 16px;
  border-top: 1px solid $gray-5;
  border-right: 1px solid $gray-5;
}

.program-description {
  padding: 16px;
  border-top: 1px solid $gray-5;
}

.link {
  cursor: pointer;
}
