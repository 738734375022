@import "~styles/zeplin-colors";

.wrap {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  p {
    margin: 0;
  }
}

.user-image-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: $pliva-blue;
  border-radius: 50%;
  margin-right: 8px;
}
