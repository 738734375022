.row {
  display: flex;
  justify-content: flex-start;
}

.col-interactions {
  width: 70%;
}

.col-comments {
  width: 30%;
}
