@import "~styles/zeplin-colors";

.header {
  display: flex;
  justify-content: space-between;
}

.add {
  color: $blue-1;
  cursor: pointer;
}

.cogwheel {
  color: $blue-1;
}
