@import "~styles/zeplin-colors";

.wrap {
  display: flex;
  justify-content: flex-start;
}

.menu-wrap {
  width: 20%;
  margin-right: 32px;
}

.patient-info-wrap {
  width: 80%;
  display: flex;
  justify-content: flex-start;
}

.patient-general-info-wrap {
  width: 55%;
  margin-right: 60px;
}

.patient-health-info-wrap {
  width: 45%;
  margin-top: 50px;
}

.title {
  margin-bottom: 32px;
}
