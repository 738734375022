@import "~styles/zeplin-colors";

.wrap {
  margin-left: 16px;
}

.card-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-gap: 1rem;
  justify-content: flex-start;
  align-content: flex-start;
  margin: 2vh auto;
}

.subtitle {
  display: block;
  color: $gray-7;
  margin-bottom: 0 !important;
}

.subtitle-text {
  display: block;
  color: $gray-9;
  margin-bottom: 0 !important;
}
