.menu {
  min-height: calc(100vh - 76px);

  // overrides for antd menu item style
  :global(.ant-menu-item-only-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-size: 16px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    height: 48px !important;
  }
}

.logo-wrap {
  padding: 24px;
}
