@import "~styles/zeplin-colors";

.completed {
  color: $green-1;
}

.success-button {
  color: white;
  background-color: $green-1 !important;
  border-color: $green-1 !important;
}
