@import "~styles/zeplin-colors";

.wrap {
  background: white;
  margin: 16px;
  padding: 16px;
}

.short-margin-left-top {
  margin-left: 8px;
  margin-top: 0;
}

.short-margin-right-top {
  margin-right: 8px;
  margin-top: 0;
}

.no-padding {
  padding: 0;
}

.activityWrap {
  background: white;
  margin: 0 12px 16px 16px;
  padding: 16px 16px 16px 0;
}
