@import "~styles/zeplin-colors";

.background {
  background-color: $gray-1;
  border: 1px solid $gray-5;
  overflow: auto;
}

.noBorderBottom {
  border-bottom: 0;
}

.listItem {
  &:global(.ant-list-item) {
    // specifity too big from antd
    border-bottom: 0 !important;
    padding: 8px 0;
  }

  .text {
    padding: 8px 16px;
  }
}

.messageOutbound {
  &:global(.ant-list-item) {
    justify-content: flex-end;
  }

  .text {
    background-color: $blue-1;
    border-radius: 16px 16px 0 16px;
    color: $gray-1;
    margin-right: 8px;
  }

  .timestamp {
    left: -40px;
  }
}

.messageInbound {
  .text {
    background-color: $gray-4;
    color: $gray-9;
    border-radius: 16px 16px 16px 0;
    margin-left: 8px;
  }

  .timestamp {
    right: -40px;
  }
}

.text {
  max-width: 60%;
  position: relative;
}

.timestamp {
  font-size: 12px;
  color: $gray-6;
  position: absolute;
  bottom: 0;
}
