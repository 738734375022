@import "~styles/zeplin-colors";

.exportLink {
  color: $blue-2;
  cursor: pointer;
}

.nonLink {
  color: $gray-6;
}

.tableTitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
}

.table {
  margin-top: 20px;

  :global(.ant-table-tbody tr .ant-table-cell:last-child) {
    text-align: right;
  }
}
