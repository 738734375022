@import "~styles/zeplin-colors";

.hand-entry {
  color: $blue-1;
  margin: 0 !important;
  padding: 0 !important;
}

.button-wrap {
  display: flex;
  justify-content: flex-end;
}
