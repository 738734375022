@import "~styles/zeplin-colors";

.cogwheel {
  color: $blue-1;
}

.menuItemWidth {
  width: 50px;
}

.table {
  margin-top: 20px;

  :global(.ant-table-thead tr .ant-table-cell:last-child) {
    width: 50px;
  }

  :global(.ant-table-tbody tr .ant-table-cell:last-child) {
    width: 50px;
  }
}

.tableTitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
}
