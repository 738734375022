@import "~styles/zeplin-colors";

.table {
  :global(.ant-table-thead .ant-table-cell:first-child) {
    min-width: 124px;
  }

  :global(.ant-table-thead .ant-table-cell:nth-child(4)) {
    min-width: 110px;
  }
}

.sucessful-state {
  color: $green-1;
}

.cancelled-state {
  color: $red-1;
}
