.menu {
  min-height: 600px;
  // overrides for antd menu item style
  :global(.ant-menu-item-only-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-size: 14px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 16px !important;
    min-width: 200px !important;
    display: flex;
    height: auto !important;
    line-height: 22px !important;
    white-space: normal;
  }

  .menu-item-wrap {
    margin: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: red;
    min-width: 175px;
    width: 100%;
  }

  .link-paragraph {
    word-wrap: break-word;
    padding: 0;
    margin: 0;
  }

  .completed-icon {
    justify-self: flex-end;
    color: green;
  }

  .unsuccessful-icon {
    justify-self: flex-end;
    color: red;
  }
}
