@import "~styles/zeplin-colors";

.wrap {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.personal-info-title {
  margin-top: 20px;
}

.submit {
  margin-top: 24px;
}
