@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./static/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: local("Roboto"), url(./static/fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: local("Roboto"), url(./static/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}

body {
  margin: 0;
  padding: 0;
}

* {
  font-family: Roboto, sans-serif;
}
