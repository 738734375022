@import "~styles/zeplin-colors";

.no-left-padding-wrap {
  background: white;
  margin: 24px;
  padding: 24px 24px 24px 0;
}

.wrap {
  background: white;
  margin: 24px;
  padding: 24px;
}

.cover {
  min-height: calc(100vh - 176px);
}
