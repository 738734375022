@import "~styles/zeplin-colors";

.wrapper {
  min-height: 100vh;

  // header background
  :global(.ant-layout-header) {
    background: white;
    box-shadow: 0 0 15px 0 rgba(19, 20, 23, 0.1);
    padding: 24px;
    z-index: 2;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content-wrapper {
  min-width: 900px;
  background: $pale-grey;
}
