.menu {
  min-height: 600px;
  // overrides for antd menu item style
  :global(.ant-menu-item-only-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-size: 14px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 16px !important;
    height: 44px !important;
  }
}
