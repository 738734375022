@import "~styles/zeplin-colors";

.wrap {
  display: grid;
  align-items: start;
  grid-template-columns: 1fr 1fr 1fr 280px;
  grid-auto-flow: dense;
  grid-gap: 24px;
  padding: 24px;
}

.otherCardsWrap {
  margin-top: -15px;
  grid-column-start: 1;
  grid-column-end: 3;
}

.marginWrap {
  display: inline-block;
  margin-top: 0;
  margin-right: 24px;
}

@media only screen and (max-width: 1400px) {
  .otherCardsWrap {
    grid-column-start: 2;
    grid-column-end: 5;
  }
}

@media only screen and (max-width: 1100px) {
  .otherCardsWrap {
    grid-column-start: 1;
    grid-column-end: 5;
  }
}
