@import "~styles/zeplin-colors";

.wrap {
  border: 1px solid $gray-5;
  display: flex;
  justify-content: space-between;
}

.left {
  display: inline-block;
}

.right {
  display: inline-block;
  padding: 6px;
  cursor: pointer;
}

.img-wrap {
  display: inline-block;
  padding: 8px;
  width: 56px;
  height: 56px;
  cursor: pointer;
}

.img {
  border: 1px solid $gray-5;
  width: 100%;
  height: 100%;
}

.name {
  margin-left: 8px;
  vertical-align: middle;
  align-self: center;
}
